import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

class Loader extends React.PureComponent {
    componentDidUpdate = () => {
        const {isLoader} = this.props;

        document.querySelector('body').style.overflow = isLoader ? 'hidden' : 'auto';
    };

    render = () => {
        const {className, isLoader} = this.props;

        return isLoader && (
            <div className={classnames(styles['loader-wrapper'], className)}>
                <span className={styles['loader-wrapper-spinner']}/>
            </div>
        );
    }
}

Loader.propTypes = {
    className: PropTypes.string,
    isLoader: PropTypes.bool.isRequired
};

Loader.defaultProps = {
    className: ''
};

export {Loader as TestableLoader};
export default Loader;
