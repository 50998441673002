import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Col} from 'react-flexbox-grid';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';
import styles from './index.module.scss';

const Column = ({children, className, constant, ...props}) => {
    const colClassName = classnames(styles['column'], '_column', className, {[styles['column_constant']]: constant});

    return <Col {...props} className={colClassName}>{children}</Col>;
};

Column.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    constant: PropTypes.bool
};

Column.defaultProps = {
    className: '',
    constant: false
};

export {Column as TestableColumn};
export default React.memo(Column);
