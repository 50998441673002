import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Grid} from 'react-flexbox-grid';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';
import styles from './index.module.scss';

const Container = ({className, children, ...props}) => {
    const containerClassName = classnames(styles['container'], className);

    return <Grid {...props} className={containerClassName}>{children}</Grid>;
};

Container.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    fluid: PropTypes.bool
};

Container.defaultProps = {
    className: ''
};

export {Container as TestableContainer};
export default React.memo(Container);
