import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const CLASS_NAME_BY_TYPE = {dashed: styles['separator_dashed'], solid: styles['separator_solid']};

const Separator = ({type, className}) => {
    const separatorClassName = classnames(styles['separator'], CLASS_NAME_BY_TYPE[type], className);

    return <div className={separatorClassName}/>;
};

Separator.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string
};

Separator.defaultProps = {
    type: 'dashed',
    className: ''
};

export {Separator as TestableSeparator};
export default React.memo(Separator);
